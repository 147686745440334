@media (max-width: 1540px) {
}
@media (max-width: 1280px) {
  .mainContainer {
    margin-top: 6rem !important;
  }
}
@media (max-width: 1000px) {
  .mainContainer {
    margin-top: 5rem !important;
  }
}
@media (max-width: 1219px) {
  .tophead {
    margin-top: 5rem !important;
  }
}



@media (max-width: 767px) {
}
